import { useContext, useState } from 'react';
import { Mixpanel } from '../services/Mixpanel';
import { ShareSchoolPromoCode } from './ExtendTrialWithSchoolInfo';
import { submitSurveyTrialExtension } from '../services/UserService';
import { getTrialEndDate } from '../utils/isPremiumUser';
import { DiffitUserContext } from '../contexts/DiffitUserContext';
import { useUser } from '@clerk/clerk-react';

export function ExtendTrialWithSurvey({ onClose }) {
  const [survey, setSurvey] = useState({
    disappointed: '',
    type_of_people: '',
    benefit: '',
    improve: '',
  });
  const [needsResponse, setNeedsResponse] = useState({
    disappointed: false,
    type_of_people: false,
    benefit: false,
    improve: false,
  });

  const [promoCode, setPromoCode] = useState('');

  const { user } = useUser();
  const { diffitUser, refreshDiffitUser } = useContext(DiffitUserContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSurvey((prev) => ({ ...prev, [name]: value }));
  };

  const getSuccessTitle = () => {
    const userTrialEndDate = getTrialEndDate(user, diffitUser);
    return `Success! Your trial now ends on ${userTrialEndDate}!`;
  };

  return (
    <div className='flex flex-col items-center text-center px-1 max-h-full h-full'>
      {promoCode ? (
        <ShareSchoolPromoCode
          title={getSuccessTitle()}
          promoCode={promoCode}
          schoolName=''
          onClose={onClose}
        />
      ) : (
        <div>
          <div className='pb-8'>
            <p className='text-black text-lg font-semibold'>
              Share your thoughts to get 4 more months of premium!
            </p>
          </div>
          <div className='w-full flex gap-x-3 text-gray-800 text-sm'>
            <div className='flex flex-col gap-5'>
              <div>
                <p className='text-sm font-semibold text-left pb-2'>
                  If Diffit were no longer available, how disappointed would you be?
                </p>
                <div className='flex flex-col gap-y-1 pl-4'>
                  <div className='flex items-center gap-x-2'>
                    <input
                      className='text-purple-500 focus:ring-purple-500'
                      type='radio'
                      id='not-at-all'
                      name='disappointed'
                      value='very'
                      onChange={handleChange}
                    />
                    <label htmlFor='not-at-all'>Very disappointed</label>
                  </div>
                  <div className='flex items-center gap-x-2'>
                    <input
                      className='text-purple-500 focus:ring-purple-500'
                      type='radio'
                      id='moderately'
                      name='disappointed'
                      value='somewhat'
                      onChange={handleChange}
                    />
                    <label htmlFor='moderately'>Somewhat disappointed</label>
                  </div>
                  <div className='flex items-center gap-x-2'>
                    <input
                      className='text-purple-500 focus:ring-purple-500'
                      type='radio'
                      id='very'
                      name='disappointed'
                      value='not'
                      onChange={handleChange}
                    />
                    <label htmlFor='very'>Not disappointed</label>
                  </div>
                </div>
                <p
                  className={`${needsResponse.disappointed ? 'block' : 'invisible'} w-full text-left text-red-500 text-xs`}>
                  Please provide a response
                </p>
              </div>
              <div>
                <label
                  name='type_of_people'
                  className='text-sm font-semibold text-left pb-1 w-full inline-block'>
                  What type of people do you think would most benefit from Diffit?
                </label>
                <input
                  name='type_of_people'
                  onChange={handleChange}
                  type='text'
                  className='h-[32px] border rounded-md w-full focus:border-purple-500 focus:ring-purple-500 border-gray-300 text-gray-800 placeholder:text-sm placeholder:text-gray-300'
                />
                <p
                  className={`${needsResponse.type_of_people ? 'block' : 'invisible'} w-full text-left text-red-500 text-xs`}>
                  Please provide a response
                </p>
              </div>
              <div>
                <label
                  name='benefit'
                  className='text-sm font-semibold text-left pb-1 w-full inline-block'>
                  What is the main benefit you receive from Diffit?
                </label>
                <input
                  name='benefit'
                  onChange={handleChange}
                  type='text'
                  className='h-[32px] border rounded-md w-full focus:border-purple-500 focus:ring-purple-500 border-gray-300 text-gray-800 placeholder:text-sm placeholder:text-gray-300'
                />
                <p
                  className={`${needsResponse.benefit ? 'block' : 'invisible'} w-full text-left text-red-500 text-xs`}>
                  Please provide a response
                </p>
              </div>
              <div>
                <label
                  name='improve'
                  className='text-sm font-semibold text-left pb-1 w-full inline-block'>
                  How can we improve Diffit for you?
                </label>
                <input
                  name='improve'
                  onChange={handleChange}
                  type='text'
                  className='h-[32px] border rounded-md w-full focus:border-purple-500 focus:ring-purple-500 border-gray-300 text-gray-800 placeholder:text-sm placeholder:text-gray-300'
                />
                <p
                  className={`${needsResponse.improve ? 'block' : 'invisible'} w-full text-left text-red-500 text-xs`}>
                  Please provide a response
                </p>
              </div>
            </div>
          </div>

          <div className='flex flex-row gap-4 w-full py-4'>
            <button
              onClick={() => {
                // Check to see if responses are empty
                const response = { ...survey };
                let needsResponseClone = { ...needsResponse };
                let hasEmptyResponse = false;
                for (const key in response) {
                  if (response[key].trim() === '') {
                    needsResponseClone[key] = true;
                    hasEmptyResponse = true;
                  } else {
                    needsResponseClone[key] = false;
                  }
                }
                setNeedsResponse(needsResponseClone);
                if (hasEmptyResponse) {
                  Mixpanel.track(
                    'Submitted extend_trial_with_survey upgrade modal with empty response',
                    {
                      disappointed: survey.disappointed,
                      type_of_people: survey.type_of_people,
                      benefit: survey.benefit,
                      improve: survey.improve,
                    }
                  );
                  return;
                }
                Mixpanel.track('Submitted extend_trial_with_survey upgrade modal', {
                  disappointed: survey.disappointed,
                  type_of_people: survey.type_of_people,
                  benefit: survey.benefit,
                  improve: survey.improve,
                });
                submitSurveyTrialExtension(survey)
                  .then((res) => {
                    if (res.data?.promo_code) {
                      setPromoCode(res.data.promo_code);
                    }
                    refreshDiffitUser();
                  })
                  .catch((err) => {
                    alert(err?.response?.data?.message || 'Error submitting survey');
                  });
              }}
              className='w-full whitespace-nowrap gap-1 rounded-md bg-purple-700 disabled:bg-purple-400 px-4 py-2 text-white hover:bg-purple-600 focus:outline-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'>
              Extend My Trial!
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
