const getButtonClass = ({ variant, disabled }) => {
  switch (variant) {
    case 'primary':
      if (disabled) {
        return 'bg-gray-300 w-full px-3 py-2 text-gray-500 focus:outline-emerald-500 pointer-events-none';
      } else {
        return 'bg-emerald-600 hover:bg-emerald-500 w-full px-3 py-2 text-white focus:outline-emerald-500';
      }

    case 'secondary':
      if (disabled) {
        return 'bg-white w-full px-3 py-2 text-gray-400 border-2 border-gray-200 focus:outline-gray-300 pointer-events-none';
      } else {
        return 'bg-white hover:bg-gray-100 w-full px-3 py-2 text-emerald-600 border border-emerald-600 focus:outline-emerald-500';
      }
    case 'tertiary':
      return 'border border-gray-300 text-gray-700 bg-white text-gray-900 hover:bg-gray-100 flex items-center justify-center w-full px-3 py-2 ';
    default:
      return 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  ';
  }
};

export const Button = ({ onClick, variant, disabled, children }) => {
  const classes = getButtonClass({ variant, disabled });
  return (
    <button onClick={onClick} className={classes + ' rounded-md shadow-sm'} disabled={disabled}>
      {children}
    </button>
  );
};
