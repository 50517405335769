import { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';

import PrimaryButton from './PrimaryButton';
import SignInModal from './SignInModal';
import LoadingSpinner from './LoadingSpinner';
import { Mixpanel } from '../services/Mixpanel';
import { useSearchParams } from 'react-router-dom';

export default function SignInButton() {
  const [query, setQuery] = useSearchParams();
  const { isLoaded, isSignedIn } = useUser();
  const [open, setOpen] = useState(false);
  const [startInSignUp, setStartInSignUp] = useState(false);

  function handleClick() {
    Mixpanel.track('Log In Button');
    setOpen(true);
  }

  useEffect(() => {
    if (query.get('signup') && !isSignedIn && isLoaded) {
      setOpen(true);
      query.delete('signup');
      setStartInSignUp(true);
      setQuery(query);
    }
  }, [query, isLoaded, isSignedIn]);

  return (
    <>
      <PrimaryButton text='Log In' shown={!isSignedIn & isLoaded} action={handleClick} />
      {!isLoaded && <LoadingSpinner />}
      <SignInModal open={open} setOpen={setOpen} startInSignUp={startInSignUp} />
    </>
  );
}
