export default function PrimaryButton({ text, shown, action }) {
  return (
    <div className={shown ? '' : 'hidden'}>
      <button
        type='button'
        onClick={action}
        className='rounded-md bg-emerald-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600'>
        {text}
      </button>
    </div>
  );
}
