import CreateAxiosInstance from './axiosConfig';
const APIClient = CreateAxiosInstance(import.meta.env.VITE_API_BASE_URL);

export function saveUserSettings({ gradeLevels, subjects }) {
  return APIClient.post(`/user/settings`, {
    grade_levels: gradeLevels,
    subjects: subjects,
  });
}

export function getUserSettings() {
  return APIClient.get(`/user/settings`);
}
