import CreateAxiosInstance from './axiosConfig';
const APIClient = CreateAxiosInstance(import.meta.env.VITE_API_BASE_URL);

export async function getSchoolRecordsByLocation({ city, state, country, lat, lon }) {
  return await APIClient.get(`/school-records/get_schools_in_city`, {
    params: {
      city,
      state,
      country,
      lat,
      lon,
    },
    customErrorHandler: function (error) {
      alert(error?.response?.data?.message || 'Error getting organizations in zipcode');
    },
  });
}

export async function connectUserToExistingSchoolRecord({ school_id, generatePromoCode }) {
  return await APIClient.patch(`/school-records/connect`, {
    school_id,
    generate_promo_code: generatePromoCode,
  });
}

export async function createSchoolRecordAndConnect({
  name,
  city,
  state,
  country,
  generatePromoCode,
  is_district,
}) {
  return await APIClient.post(`/school-records/connect`, {
    name,
    city,
    state,
    country,
    generate_promo_code: generatePromoCode,
    is_district: is_district,
  });
}
