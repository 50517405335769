import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useState, useCallback, useEffect } from 'react';
import { EnvelopeIcon, ClipboardIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
  connectUserToExistingSchoolRecord,
  createSchoolRecordAndConnect,
  getSchoolRecordsByLocation,
} from '../services/SchoolRecordService';
import { Combobox, Disclosure, Transition } from '@headlessui/react';
import { Mixpanel } from '../services/Mixpanel';
import { usePlacesWidget } from 'react-google-autocomplete';
import LoadingSpinner from './LoadingSpinner';
import { toTitleCase } from '../utils/string';
import { debounce } from 'lodash';

const CANT_FIND_SCHOOL_OPTION = {
  id: -1,
  name: "I don't see my school",
};

const focusOnSchoolNameInput = () => {
  const input = document.getElementById('new-school-name-input');
  if (input) {
    setTimeout(() => {
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    });
  }
};

const toggleAddNewSchoolForm = () => {
  const disclosureButton = document.getElementById('toggle-disclosure-button');
  if (disclosureButton) {
    disclosureButton.click();
  }
};

const validateSchoolInfo = (schoolInfo, country) => {
  const isUSA = country === 'United States';
  // must always have name, enrollment, and city. must have state if USA
  if (!schoolInfo.name || !schoolInfo.city) {
    return false;
  }
  if (isUSA && !schoolInfo.state) {
    return false;
  }
  return true;
};

const getSuccessTitle = () => {
  const newEndDate = new Date();
  newEndDate.setMonth(newEndDate.getMonth() + 4);
  newEndDate.setHours(23, 59, 59, 999);
  // format in MM/DD/YYYY
  const newEndDateFormatted = newEndDate.toLocaleDateString('en-US');
  return `Success! Your trial now ends on ${newEndDateFormatted}!`;
};

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

export function ExtendTrialWithSchoolInfo({ onClose, onRedeem }) {
  const [city, setCity] = useState({});
  const [state, setState] = useState({});
  const [country, setCountry] = useState({});
  const [isLoadingSchools, setIsLoadingSchools] = useState(false); // true when we're fetching schools
  const [loadedSchools, setLoadedSchools] = useState([]);
  const [schoolQuery, setSchoolQuery] = useState(''); // the string they've typed into the search bar
  const [selectedSchool, setSelectedSchool] = useState(); // the actual school they've selected (from our list)
  const [newSchoolInfo, setNewSchoolInfo] = useState({}); // the school info they've entered manually, { name, city, state?, country }
  const [showAddSchoolForm, setShowAddSchoolForm] = useState(false);
  const [schoolsInSearch, setSchoolsInSearch] = useState([]);
  const [promoCode, setPromoCode] = useState('');
  const { ref: placesRef } = usePlacesWidget({
    apiKey: API_KEY,
    onPlaceSelected: async (place) => {
      setIsLoadingSchools(true);

      const googleCity = place.address_components.find(
        (component) =>
          component.types.includes('locality') ||
          component.types.includes('administrative_area_level_3')
      ); // Documentation on the various types: https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types

      const googleCountry = place.address_components.find((component) =>
        component.types.includes('country')
      );

      let googleState = {};
      if (googleCountry.long_name === 'United States') {
        googleState = place.address_components.find((component) =>
          component.types.includes('administrative_area_level_1')
        );
      }

      const lat = place.geometry.location.lat();
      const lon = place.geometry.location.lng();

      Mixpanel.track('Place selected from Google Places', {
        city: googleCity,
        state: googleState,
        country: googleCountry,
        lat,
        lon,
      });

      const response = await getSchoolRecordsByLocation({
        city: googleCity.long_name,
        state: googleState.short_name, // this will be undefined in non-US contexts, and that's ok
        country: googleCountry.long_name,
        lat,
        lon,
      });
      setCity(googleCity);
      setState(googleState);
      setCountry(googleCountry);
      setLoadedSchools(response.data.records || []);
      setIsLoadingSchools(false);

      setTimeout(() => {
        const input = document.getElementById('search-input');
        input?.focus();
      }, 100);
    },
  });

  const searchDisabled = isLoadingSchools || !city?.long_name;

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query) {
        setSchoolsInSearch([]);
        return;
      }
      if (query.length < 3) {
        setSchoolsInSearch([]);
        return;
      }
      if (!loadedSchools) {
        return;
      }
      const lowerCaseQuery = query.toLowerCase();
      const lowerCaseSchools =
        loadedSchools?.map((school) => ({
          ...school,
          lowerCaseName: school.name.toLowerCase(),
        })) || [];

      // Filter and limit results
      let schools = [];
      for (let i = 0; i < lowerCaseSchools.length && schools.length < 20; i++) {
        if (lowerCaseSchools[i].lowerCaseName.includes(lowerCaseQuery)) {
          schools.push(lowerCaseSchools[i]);
        }
      }
      schools.push(CANT_FIND_SCHOOL_OPTION);
      setSchoolsInSearch(schools);
    }, 50),
    [loadedSchools]
  );

  useEffect(() => {
    debouncedSearch(schoolQuery);
  }, [schoolQuery]);

  const confirmButtonEnabled =
    selectedSchool || validateSchoolInfo(newSchoolInfo, country.long_name);

  const onSubmitSchool = async () => {
    Mixpanel.track('School info form submitted', {
      connectToExistingSchool: !!selectedSchool,
      country: country.long_name,
    });

    // if we have a selected school, use that
    if (selectedSchool) {
      const response = await connectUserToExistingSchoolRecord({
        school_id: selectedSchool.id,
      });
      if (response?.data?.promo_code) {
        onRedeem?.();
        setPromoCode(response.data.promo_code);
      } else {
        console.error('Error connecting user to school', response);
      }
    } else {
      const response = await createSchoolRecordAndConnect({
        ...newSchoolInfo,
        country: country.long_name,
      });
      if (response?.data?.promo_code) {
        onRedeem?.();
        setPromoCode(response.data.promo_code);
      } else {
        console.error('Error creating school and connecting user', response);
      }
    }
  };

  return (
    <div className='flex flex-col items-center text-center gap-y-3 overflow-auto px-1'>
      {promoCode ? (
        <ShareSchoolPromoCode
          title={getSuccessTitle()}
          promoCode={promoCode}
          schoolName={selectedSchool?.name || newSchoolInfo?.name}
          onClose={() => {
            Mixpanel.track('Share School Promo Code Modal Closed');
            onClose(true);
          }}
        />
      ) : (
        <div>
          <p className='text-black text-lg font-semibold'>Extend Your Trial</p>
          <p className='text-gray-500 text-sm my-4'>
            Select your school to get a 4 month extension for you and your fellow teachers!
          </p>
          <div className='w-full flex gap-x-3 text-gray-800 text-sm'>
            <div className={`w-full`}>
              <label htmlFor='city-input' className={`block text-left`}>
                School Location
              </label>

              <input
                ref={placesRef}
                onChange={() => {
                  setSchoolQuery('');
                  setSelectedSchool();
                  setNewSchoolInfo({});
                  if (showAddSchoolForm) {
                    toggleAddNewSchoolForm();
                  }
                }}
                id='city-input'
                className='h-[40px] border rounded-md w-full focus:border-purple-500 focus:ring-purple-500 border-gray-300 text-gray-800 placeholder:text-sm placeholder:text-gray-300'
                type='text'
                placeholder='City, State, Country'
              />
            </div>
          </div>

          <Combobox
            className='w-full text-sm mt-3'
            as='div'
            aria-labelledby='search'
            value={schoolQuery}>
            <Combobox.Label
              className={`relative block text-left ${searchDisabled ? 'text-gray-300' : ''}`}>
              School Name
            </Combobox.Label>

            <>
              <div className='relative'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-3'>
                  {isLoadingSchools ? (
                    <LoadingSpinner className={`h-5 w-5 text-purple-500`} aria-hidden='true' />
                  ) : (
                    <MagnifyingGlassIcon
                      className={`h-5 w-5 ${searchDisabled ? 'text-gray-300' : 'text-gray-400'}`}
                      aria-hidden='true'
                    />
                  )}
                </div>
                <Combobox.Input
                  id='search-input'
                  className={`w-full pl-10 sm:text-sm sm:leading-6 rounded-md border ring-0 focus:border-purple-500 focus:ring-purple-500 ${
                    searchDisabled
                      ? 'placeholder-gray-200 border-gray-300'
                      : 'placeholder-gray-400 border-gray-400'
                  }`}
                  onFocus={(e) => {
                    searchDisabled && e.target.blur();
                  }}
                  placeholder={searchDisabled ? '' : 'Search for your school'}
                  disabled={searchDisabled}
                  value={schoolQuery}
                  onChange={(e) => {
                    if (showAddSchoolForm) {
                      toggleAddNewSchoolForm();
                    }
                    setSchoolQuery(e.target.value);
                    setSelectedSchool();
                    setNewSchoolInfo({});
                  }}
                  onBlur={(e) => {
                    if (e.target.id !== 'search-input') {
                      Mixpanel.track('School search input blurred without selecting', {
                        searchQuery: schoolQuery,
                      });
                    }
                  }}
                />
              </div>
            </>
            <Transition
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              afterLeave={() => {}}>
              <Combobox.Options
                id='list-of-options'
                className='absolute bg-white w-[90%] z-[999999] max-h-[200px] border rounded-md border-gray-500 empty:hidden overflow-auto'>
                {schoolsInSearch.map((school) => (
                  <Combobox.Option
                    key={school.id}
                    value={school.name}
                    className='group flex cursor-default items-center gap-2 py-1.5 px-3 hover:bg-purple-100 '>
                    <button
                      id='school-option-button'
                      onClick={(e) => {
                        if (school.id === CANT_FIND_SCHOOL_OPTION.id) {
                          Mixpanel.track('Cant find your school selected from search', {
                            school: school.name,
                          });
                          if (!showAddSchoolForm) {
                            toggleAddNewSchoolForm();
                          } else {
                            focusOnSchoolNameInput();
                          }
                          return;
                        }
                        Mixpanel.track('School selected from search', {
                          school: school.name,
                        });

                        setSchoolQuery(school.name);
                        setSelectedSchool(school);
                        setTimeout(() => {
                          const input = document.getElementById('search-input');
                          input.blur();
                        }, 0);
                      }}
                      className='w-full text-left py-2'>
                      {school.name}
                      {school.id !== CANT_FIND_SCHOOL_OPTION.id && (
                        <span className='text-gray-400 text-sm ml-1 italic'>
                          ({toTitleCase(school.city)}
                          {school.state && `, ${school.state.toUpperCase()}`})
                        </span>
                      )}
                    </button>
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
            <p
              className={`text-xs text-left text-purple-600 ${!searchDisabled ? 'invisible' : ''}`}>
              {isLoadingSchools ? '' : 'Enter your city to search schools'}
            </p>
          </Combobox>

          {
            <div
              className={`w-full max-h-full ${schoolQuery && !selectedSchool ? '' : 'invisible'}`}>
              <Disclosure as='div' className='w-full max-h-full'>
                <Disclosure.Button
                  id='toggle-disclosure-button'
                  onClick={(e) => {
                    setShowAddSchoolForm((prev) => {
                      Mixpanel.track("Don't see your school toggled", {
                        willShowAddSchoolForm: !prev,
                      });
                      const newVal = !prev;
                      if (newVal) {
                        setTimeout(() => {
                          focusOnSchoolNameInput();
                        }, 100);
                      }
                      return newVal;
                    });
                    setNewSchoolInfo({
                      name: schoolQuery,
                      city: city.long_name,
                      state: state?.short_name || '',
                      country: country.long_name,
                    });
                  }}
                  className='flex mb-3 w-full text-sm font-medium text-left text-purple-500 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                  <span className=' underline'>Don't see your school?</span>
                  <ChevronDownIcon
                    className={`${showAddSchoolForm ? 'transform rotate-180' : ''} w-5 h-5 text-purple-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className='w-full flex text-sm'>
                  <div className='flex flex-col w-full gap-y-3 mb-5'>
                    <p className='text-left font-semibold text-base '>Enter school details:</p>
                    <div>
                      <FormInput
                        value={newSchoolInfo.name || ''}
                        onChange={(e) => {
                          setNewSchoolInfo((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                        }}
                        label='School Name'
                        id='new-school-name-input'
                        type='text'
                        placeholder="Enter your school's name"
                      />
                    </div>

                    <div>
                      <FormInput
                        label='City'
                        id='city-input'
                        type='text'
                        placeholder='Enter your city'
                        value={newSchoolInfo.city || ''}
                        onChange={(e) => {
                          setNewSchoolInfo((prev) => ({
                            ...prev,
                            city: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className={`${country.long_name !== 'United States' ? 'hidden' : ''}`}>
                      <FormInput
                        label='State'
                        id='state-input'
                        type='text'
                        placeholder='Enter your state'
                        value={newSchoolInfo.state || ''}
                        onChange={(e) => {
                          setNewSchoolInfo((prev) => ({
                            ...prev,
                            state: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className={`${country.long_name === 'United States' ? 'hidden' : ''}`}>
                      <FormInput
                        label='Country'
                        id='country-input'
                        type='text'
                        placeholder='Enter your state'
                        value={newSchoolInfo.country || ''}
                        onChange={(e) => {
                          setNewSchoolInfo((prev) => ({
                            ...prev,
                            country: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </Disclosure.Panel>
              </Disclosure>
            </div>
          }

          <div className='flex flex-row gap-4 w-full pb-2 '>
            <button
              className='w-full whitespace-nowrap gap-1 rounded-md bg-none px-4 py-2 ring-1 ring-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 text-gray-800'
              onClick={() => {
                Mixpanel.track('Closed extend_with_school upgrade modal without selecting school', {
                  locationInputValue: placesRef.current?.value,
                  selectedCity: city?.long_name,
                  selectedSchool: selectedSchool,
                  newSchoolInfo: newSchoolInfo,
                });
                onClose();
              }}>
              Skip
            </button>
            <button
              className='w-full whitespace-nowrap gap-1 rounded-md bg-purple-700 disabled:bg-purple-400 px-4 py-2 text-white hover:bg-purple-600 focus:outline-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
              onClick={onSubmitSchool}
              disabled={!confirmButtonEnabled}>
              Extend Trial!
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const FormInput = ({ label, id, type, placeholder, onChange, value }) => {
  return (
    <>
      <label htmlFor={id} className='block text-left text-gray-800'>
        {label}
      </label>
      <input
        id={id}
        value={value}
        className='w-full p-2 border rounded-md border-gray-300 focus:border-purple-500 focus:ring-purple-500 text-gray-800 text-sm placeholder-gray-400'
        type={type}
        placeholder={placeholder}
        onChange={onChange}
      />
    </>
  );
};

export const ShareSchoolPromoCode = ({ title, promoCode, schoolName, onClose }) => {
  const [copied, setCopied] = useState(false);
  const [emailed, setEmailed] = useState(false);

  return (
    <div className='flex flex-col items-center text-center gap-y-3 '>
      <p className='text-gray-800 text-lg font-semibold px'>{title}</p>
      {!!schoolName ? (
        <p className='text-gray-800 text-sm'>
          Share the love! You can share this promo code with other teachers at {schoolName} -
          they'll get the 4 month extension of premium access once they confirm they work at your
          school.
        </p>
      ) : (
        <p className='text-gray-800 text-sm'>
          Share the love! You can share this promo code with other teachers at your school - they'll
          also get the 4 month extension of premium access.
        </p>
      )}

      <div className='w-full flex justify-center gap-x-3 text-gray-800'>
        <div className='w-fit'>
          <div className='flex flex-col items-center px-10 gap-x-2 '>
            <p className='text-gray-800 font-semibold text-lg bg-purple-100 p-2 rounded-lg w-full'>
              diffit.me/redeem?code={promoCode}
            </p>

            <div className='flex flex-row mt-5 gap-y-2'>
              <button
                type='button'
                onClick={() => {
                  navigator.clipboard.writeText(`diffit.me/redeem?code=${promoCode}`);
                  setCopied(true);
                  Mixpanel.track('Share org promo code button clicked', {
                    type: 'copy',
                  });
                }}
                className='flex justify-center items-center text-gray-600 text-sm border-gray-400 border px-3 py-1 rounded-md shadow-sm'>
                {copied ? (
                  <CheckIcon className=' h-5 w-auto text-purple-600' />
                ) : (
                  <ClipboardIcon className='h-4 w-auto text-purple-600' />
                )}
                <span className=' pl-1'>Copy</span>
              </button>
              <button
                type='button'
                onClick={() => {
                  const mailto = `mailto:?subject=I got us a Diffit trial extension!&body=Hi!%0D%0A %0D%0A %0D%0A FYI, it looks like we got 4 additional months of premium Diffit access for free - here's the link: https://app.diffit.me/redeem?code=${promoCode}%0D%0A %0D%0A %0D%0A (The code is ${promoCode} if you need to enter it manually)`;
                  const anchor = document.createElement('a');
                  anchor.href = mailto;
                  anchor.click();
                  setEmailed(true);
                  Mixpanel.track('Share org promo code button clicked', {
                    type: 'email',
                  });
                }}
                className='flex justify-center items-center text-gray-600 text-sm border-gray-400 border px-3 py-1 rounded-md ml-4 shadow-sm'>
                {emailed ? (
                  <CheckIcon className='-ml-0.5 h-5 w-auto text-purple-600' />
                ) : (
                  <EnvelopeIcon className='-ml-0.5 h-4 w-auto text-purple-600' />
                )}
                <span className=' pl-1'>Email</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row w-full mt-2  bg-white'>
        <button
          className='w-full whitespace-nowrap gap-1 rounded-md bg-purple-700 px-4 py-2 text-white hover:bg-purple-600 focus:outline-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
          onClick={() => {
            onClose(true);
          }}>
          Close
        </button>
      </div>
    </div>
  );
};
