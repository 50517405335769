import { useRouteError } from 'react-router-dom';
import Header from './Header';
import { captureException } from '@sentry/react';
import { useEffect } from 'react';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  useEffect(() => {
    captureException(error, { level: 'fatal' });
  }, [error]);

  return (
    <>
      <Header />
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-4xl'>
          <div id='error-page' className='my-8 grid gap-2'>
            <h1 className='text-lg text-emerald-900 font-bold'>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
              <i>{error.statusText || error.message}</i>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
