import axios from 'axios';
import Clerk from '@clerk/clerk-js/headless';

// Create a new instance of clerk
const clerk = new Clerk(import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY);

function CreateAxiosInstance(baseURL, useAuth = true) {
  const instance = axios.create({
    timeout: 90000,
    baseURL: baseURL ? baseURL : null,
  });

  // Add clerk token to every request
  instance.interceptors.request.use(
    async (config) => {
      config.headers['LD-Session'] = localStorage.getItem('diffitSessionId');
      if (!useAuth) return config;
      await clerk.load();
      if (!clerk.session) return config;
      const bearerToken = await clerk.session.getToken({
        template: 'userinfo',
      }); // Get the current session's token
      if (bearerToken) {
        config.headers.Authorization = `Bearer ${bearerToken}`;
      }
      return config;
    },
    (error) => {
      console.error(error);
      Promise.reject(error);
    }
  );

  // add error message handler. accepts a string or a function
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error(error);

      const errorMessageHandler = error.config.customErrorHandler;

      if (errorMessageHandler) {
        if (typeof errorMessageHandler === 'function') {
          errorMessageHandler(error);
        } else if (typeof errorMessageHandler === 'string') {
          alert(errorMessageHandler);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
}

export default CreateAxiosInstance;
