export const environment = import.meta.env.ENVIRONMENT;

export const isProd = environment === 'production';

export const getScreenSize = () => {
  if (typeof window === 'undefined') {
    return 'lg';
  }
  if (window.innerWidth < 640) {
    return 'sm';
  } else if (window.innerWidth < 768) {
    return 'md';
  } else if (window.innerWidth < 1024) {
    return 'lg';
  } else if (window.innerWidth < 1280) {
    return 'xl';
  } else {
    return '2xl';
  }
}